import { gql } from 'graphql-request';

import { DOMAIN_FRAGMENT__ADMIN } from '../fragments';

export const UPDATE_DOMAIN_INFO = gql`
  mutation updateDomainInfo($id: ID!, $name: String, $assignedPartnerUserIds: [ID!]) {
    updateDomain(id: $id, name: $name, assignedPartnerUserIds: $assignedPartnerUserIds) {
      ...domain
    }
  }
  ${DOMAIN_FRAGMENT__ADMIN}
`;
