import { gql } from 'graphql-request';

import { DEAL_FRAGMENT__ADMIN } from './dealADMIN';
import { PARTNER_USER_FRAGMENT } from './partnerUser';
import { USER_FRAGMENT } from './user';

export const DOMAIN_FRAGMENT__ADMIN = gql`
  fragment domainADMIN on Domain {
    id
    name
    first_deal_start_date
    partnerUsers {
      ...partnerUser
    }
    assignedPartnerUsers {
      ...user
    }

    newestAdsDeal {
      ...dealADMIN
    }
    newestFbAdsDeal {
      ...dealADMIN
    }
    newestSeoDeal {
      ...dealADMIN
    }
    newestSxoDeal {
      ...dealADMIN
    }
    newestUxDeal {
      ...dealADMIN
    }
  }
  ${PARTNER_USER_FRAGMENT}
  ${USER_FRAGMENT}
  ${DEAL_FRAGMENT__ADMIN}
`;
