export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
}

export interface ActiveField {
  __typename?: 'ActiveField';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface ActiveFieldInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface ActivitiesCreatedAt {
  __typename?: 'ActivitiesCreatedAt';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface ActivitiesCreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']>;
}

export interface ActivitiesUserId {
  __typename?: 'ActivitiesUserId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface ActivitiesUserIdInput {
  value: Array<Scalars['ID']>;
}

export interface Activity {
  __typename?: 'Activity';
  actor_id: Scalars['String'];
  created_at: Scalars['String'];
  event_type: Scalars['String'];
  id: Scalars['ID'];
  object_id: Scalars['String'];
  object_type: Scalars['String'];
  payload: Scalars['String'];
  /**
   *
   *
   */
  summary: Scalars['String'];
  summary_template?: Maybe<Scalars['String']>;
  summary_vars: Array<Maybe<Scalars['String']>>;
  target?: Maybe<Scalars['String']>;
  user: User;
}

export interface ActivityResponse {
  __typename?: 'ActivityResponse';
  /**
   *
   *
   */
  date: Scalars['String'];
  /**
   *
   *
   */
  items: Array<Activity>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface ActorId {
  __typename?: 'ActorId';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface AgreementStartDateRange {
  __typename?: 'AgreementStartDateRange';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface AgreementStartDateRangeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']>;
}

export interface AssignedUserId {
  __typename?: 'AssignedUserId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface AssignedUserIdInput {
  value: Array<Scalars['ID']>;
}

export interface Budget {
  __typename?: 'Budget';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface BudgetInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface Closed {
  __typename?: 'Closed';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface ClosedInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export enum ColorPaletteEnum {
  AQUAMARINE = 'AQUAMARINE',
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  GREEN = 'GREEN',
  LIGHTGREEN = 'LIGHTGREEN',
  MIDNIGHTBLUE = 'MIDNIGHTBLUE',
  NAVYBLUE = 'NAVYBLUE',
  ORANGE = 'ORANGE',
  RASPBERRY = 'RASPBERRY',
  RED = 'RED',
  VIOLET = 'VIOLET',
  YELLOW = 'YELLOW',
}

export interface CommentsCreatedAt {
  __typename?: 'CommentsCreatedAt';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface CommentsCreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']>;
}

export interface CommentsResponse {
  __typename?: 'CommentsResponse';
  /**
   *
   *
   */
  rows: Array<TicketComment>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface CommentsTicketId {
  __typename?: 'CommentsTicketId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface CommentsTicketIdInput {
  value: Array<Scalars['ID']>;
}

export interface CommentsUserId {
  __typename?: 'CommentsUserId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface CommentsUserIdInput {
  value: Array<Scalars['ID']>;
}

export interface ContentQuarterly {
  __typename?: 'ContentQuarterly';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface ContentQuarterlyInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface CreatedAt {
  __typename?: 'CreatedAt';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface CreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']>;
}

export interface CrmCompanyDTO {
  __typename?: 'CrmCompanyDTO';
  /**
   *
   *
   */
  fullName: Scalars['String'];
  /**
   *
   *
   */
  id: Scalars['Int'];
}

export enum CurrencyEnum {
  AED = 'AED',
  AUD = 'AUD',
  CAD = 'CAD',
  CZK = 'CZK',
  EUR = 'EUR',
  GBP = 'GBP',
  PLN = 'PLN',
  USD = 'USD',
}

export interface Date {
  __typename?: 'Date';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface DateInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']>;
}

export interface DateRange {
  __typename?: 'DateRange';
  /**
   *
   *
   */
  from: Scalars['DateTime'];
  /**
   *
   *
   */
  to: Scalars['DateTime'];
}

export interface DateRangeInput {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}

export interface Deadline {
  __typename?: 'Deadline';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface DeadlineInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']>;
}

export interface Deal {
  __typename?: 'Deal';
  activation_amount?: Maybe<Scalars['Float']>;
  active: Scalars['Boolean'];
  amount?: Maybe<Scalars['Float']>;
  analytics_month_end_date?: Maybe<Scalars['String']>;
  content_quarterly: Scalars['Int'];
  created_at: Scalars['String'];
  currency: Scalars['String'];
  /**
   *
   *
   */
  discounts: Array<Maybe<DealDiscount>>;
  domain?: Maybe<Domain>;
  end_date?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  extra_data?: Maybe<DealExtraDataDTO>;
  id: Scalars['ID'];
  /**
   *
   *
   */
  import_id?: Maybe<Scalars['Int']>;
  /**
   *
   *
   */
  import_page_id?: Maybe<Scalars['Int']>;
  indefinite: Scalars['Boolean'];
  is_latest: Scalars['Boolean'];
  max_budget?: Maybe<Scalars['Float']>;
  /**
   *
   *
   */
  name: Scalars['String'];
  /**
   *
   *
   */
  nextDeal?: Maybe<Deal>;
  orig_real_start_date?: Maybe<Scalars['String']>;
  orig_start_date?: Maybe<Scalars['String']>;
  original_created_at?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  previousDeal?: Maybe<Deal>;
  product?: Maybe<Scalars['String']>;
  real_start_date?: Maybe<Scalars['String']>;
  start_date: Scalars['String'];
  /**
   *
   *
   */
  terminations: Array<Maybe<DealTermination>>;
  text_reduction_percent?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  url: Scalars['String'];
}

export enum DealCrmProductEnum {
  BANNERY = 'BANNERY',
  BLOG = 'BLOG',
  FB_ADS = 'FB_ADS',
  GOOGLE_ADS = 'GOOGLE_ADS',
  MOBILE = 'MOBILE',
  RWD = 'RWD',
  SEO = 'SEO',
  SEOADS = 'SEOADS',
  SXO = 'SXO',
  UX = 'UX',
}

export interface DealDiscount {
  __typename?: 'DealDiscount';
  created_at?: Maybe<Scalars['String']>;
  deal: Deal;
  end_date: Scalars['String'];
  id: Scalars['ID'];
  import_id?: Maybe<Scalars['String']>;
  original_created_at?: Maybe<Scalars['String']>;
  start_date: Scalars['String'];
  unit: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  value: Scalars['Int'];
}

export interface DealDomain {
  __typename?: 'DealDomain';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DealDomainInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface DealExtraDataDTO {
  __typename?: 'DealExtraDataDTO';
  /**
   *
   *
   */
  ads_client_pays_budget?: Maybe<Scalars['Boolean']>;
  /**
   *
   *
   */
  ads_max_budget?: Maybe<Scalars['Float']>;
  /**
   *
   *
   */
  ads_min_budget?: Maybe<Scalars['Float']>;
  /**
   *
   *
   */
  ads_percent_profit?: Maybe<Scalars['Float']>;
  /**
   *
   *
   */
  ads_profit_amount?: Maybe<Scalars['Float']>;
}

export interface DealExtraDataDTOInput {
  adsClientPaysBudget?: InputMaybe<Scalars['Boolean']>;
  adsMaxBudget?: InputMaybe<Scalars['Float']>;
  adsMinBudget?: InputMaybe<Scalars['Float']>;
  adsPercentProfit?: InputMaybe<Scalars['Float']>;
  adsProfitAmount?: InputMaybe<Scalars['Float']>;
}

export interface DealFulltext {
  __typename?: 'DealFulltext';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DealGridFilterInput {
  domain?: InputMaybe<DealDomainInput>;
  name?: InputMaybe<NameInput>;
}

export interface DealGridInput {
  filter?: InputMaybe<DealGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface DealIds {
  __typename?: 'DealIds';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DealTermination {
  __typename?: 'DealTermination';
  action?: Maybe<Scalars['String']>;
  amount_delta?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  deal: Deal;
  id: Scalars['ID'];
  import_id?: Maybe<Scalars['String']>;
  original_created_at?: Maybe<Scalars['String']>;
  penalty_amount?: Maybe<Scalars['Float']>;
  penalty_payment_date?: Maybe<Scalars['String']>;
  request_date: Scalars['String'];
  saved: Scalars['Boolean'];
  termination_date?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['String']>;
}

export interface DealType {
  __typename?: 'DealType';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DealTypeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface DealsResponse {
  __typename?: 'DealsResponse';
  /**
   *
   *
   */
  rows: Array<Deal>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface Details {
  __typename?: 'Details';
  /**
   *
   *
   */
  budget: Scalars['Int'];
  /**
   *
   *
   */
  closed: Scalars['Int'];
  /**
   *
   *
   */
  domains: Scalars['Int'];
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  open: Scalars['Int'];
  /**
   *
   *
   */
  overdue: Scalars['Int'];
  /**
   *
   *
   */
  thisWeek: Scalars['Int'];
  /**
   *
   *
   */
  today: Scalars['Int'];
}

export interface Domain {
  __typename?: 'Domain';
  /**
   *
   *
   */
  account_id?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  active_products: Array<Scalars['String']>;
  /**
   *
   *
   */
  assignedPartnerUsers: Array<Maybe<User>>;
  created_at: Scalars['String'];
  deals: Array<Maybe<Deal>>;
  /**
   *
   *
   */
  first_deal_start_date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /**
   *
   *
   */
  import_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /**
   *
   *
   */
  newestAdsDeal?: Maybe<Deal>;
  /**
   *
   *
   */
  newestFbAdsDeal?: Maybe<Deal>;
  /**
   *
   *
   */
  newestGridDeal?: Maybe<Deal>;
  /**
   *
   *
   */
  newestSeoDeal?: Maybe<Deal>;
  /**
   *
   *
   */
  newestSxoDeal?: Maybe<Deal>;
  /**
   *
   *
   */
  newestUxDeal?: Maybe<Deal>;
  /**
   *
   *
   */
  partnerUsers: Array<Maybe<User>>;
  /**
   *
   *
   */
  phraseCount?: Maybe<Scalars['Int']>;
  positioned_domain?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
}

export interface DomainDomainId {
  __typename?: 'DomainDomainId';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DomainDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface DomainFullText {
  __typename?: 'DomainFullText';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DomainFullTextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface DomainGridFilterInput {
  agreementStartDateRange?: InputMaybe<AgreementStartDateRangeInput>;
  budget?: InputMaybe<BudgetInput>;
  contentQuarterly?: InputMaybe<ContentQuarterlyInput>;
  domainId?: InputMaybe<DomainDomainIdInput>;
  domainName?: InputMaybe<DomainNameInput>;
  fulltext?: InputMaybe<DomainFullTextInput>;
  status?: InputMaybe<DomainStatusInput>;
  type?: InputMaybe<DealTypeInput>;
  userIds?: InputMaybe<UserIdsInput>;
}

export interface DomainGridInput {
  filter?: InputMaybe<DomainGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface DomainIds {
  __typename?: 'DomainIds';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DomainName {
  __typename?: 'DomainName';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface DomainStatus {
  __typename?: 'DomainStatus';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface DomainStatusInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface DomainsResponse {
  __typename?: 'DomainsResponse';
  /**
   *
   *
   */
  rows: Array<Domain>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface EmailField {
  __typename?: 'EmailField';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface EmailFieldInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface EnumResponse {
  __typename?: 'EnumResponse';
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  key: Scalars['String'];
}

export interface EnumTranslationResponse {
  __typename?: 'EnumTranslationResponse';
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  key: Scalars['String'];
  /**
   *
   *
   */
  value: Scalars['String'];
}

export interface ExternalReportDTO {
  __typename?: 'ExternalReportDTO';
  /**
   *
   *
   */
  domainId?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  domainName?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  emailSent: Scalars['Boolean'];
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  language: Scalars['String'];
  /**
   *
   *
   */
  link: Scalars['String'];
  /**
   *
   *
   */
  reportDate: Scalars['DateTime'];
}

export interface File {
  __typename?: 'File';
  id: Scalars['ID'];
  sizeInKb: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  /**
   *
   *
   */
  user?: Maybe<User>;
}

export interface GmailField {
  __typename?: 'GmailField';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface GmailFieldInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface GroupedDateRange {
  __typename?: 'GroupedDateRange';
  /**
   *
   *
   */
  from: Scalars['DateTime'];
  /**
   *
   *
   */
  to: Scalars['DateTime'];
}

export interface GroupedDateRangeInput {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}

export interface GroupedPermissionsResponse {
  __typename?: 'GroupedPermissionsResponse';
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  options: Array<PermissionsResponse>;
  /**
   *
   *
   */
  value: Scalars['String'];
}

export interface GroupedPhraseGridFilterInput {
  dateRange?: InputMaybe<GroupedDateRangeInput>;
  domainId?: InputMaybe<PhraseDomainIdInput>;
  domainName?: InputMaybe<PhraseDomainNameInput>;
}

export interface GroupedPhraseGridInput {
  filter?: InputMaybe<GroupedPhraseGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface GroupedPhrases {
  __typename?: 'GroupedPhrases';
  /**
   *
   *
   */
  id?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  name: Scalars['String'];
  /**
   *
   *
   */
  phrases: Array<Maybe<Phrase>>;
}

export interface GroupedPhrasesResponse {
  __typename?: 'GroupedPhrasesResponse';
  /**
   *
   *
   */
  rows: Array<GroupedPhrases>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface GroupedTranslatables {
  __typename?: 'GroupedTranslatables';
  /**
   *
   *
   */
  rows: Array<Maybe<TranslatableObject>>;
  /**
   *
   *
   */
  type: Scalars['String'];
}

export interface GroupedTranslatablesInput {
  rows: Array<TranslatableObjectInput>;
  type: Scalars['String'];
}

export interface Image {
  __typename?: 'Image';
  id: Scalars['ID'];
  model?: Maybe<Scalars['String']>;
  related_id?: Maybe<Scalars['ID']>;
  sizeInKb: Scalars['String'];
  thumbnail_url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  /**
   *
   *
   */
  user?: Maybe<User>;
}

export interface ImportStatus {
  __typename?: 'ImportStatus';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  value: Scalars['String'];
}

export interface ImportStatusId {
  __typename?: 'ImportStatusId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface ImportStatusIdInput {
  value: Array<Scalars['ID']>;
}

export interface ImportType {
  __typename?: 'ImportType';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Float']>;
  value: Scalars['String'];
}

export interface ImportTypeId {
  __typename?: 'ImportTypeId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface ImportTypeIdInput {
  value: Array<Scalars['ID']>;
}

export interface ImportedFilter {
  __typename?: 'ImportedFilter';
  /**
   *
   *
   */
  value: Scalars['Boolean'];
}

export interface ImportedFilterInput {
  value: Scalars['Boolean'];
}

export enum LocaleEnum {
  en_GB = 'en_GB',
  pl_PL = 'pl_PL',
}

export enum LocationEnum {
  AT = 'AT',
  BE = 'BE',
  BENL = 'BENL',
  CA = 'CA',
  CN = 'CN',
  COIN = 'COIN',
  COJP = 'COJP',
  COM = 'COM',
  COMAU = 'COMAU',
  COMUA = 'COMUA',
  COUK = 'COUK',
  COUKUK = 'COUKUK',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  HU = 'HU',
  IE = 'IE',
  IT = 'IT',
  LT = 'LT',
  LV = 'LV',
  NL = 'NL',
  NO = 'NO',
  POL = 'POL',
  PT = 'PT',
  RO = 'RO',
  RU = 'RU',
  SE = 'SE',
  SK = 'SK',
}

export interface Monitor {
  __typename?: 'Monitor';
  daily_uptime_ratio?: Maybe<Scalars['Float']>;
  domain?: Maybe<Domain>;
  friendly_name: Scalars['String'];
  id: Scalars['ID'];
  monitor_id: Scalars['Int'];
  monthly_uptime_ratio?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  weekly_uptime_ratio?: Maybe<Scalars['Float']>;
}

export interface MonitorDomainName {
  __typename?: 'MonitorDomainName';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface MonitorDomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface MonitorFullText {
  __typename?: 'MonitorFullText';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface MonitorFullTextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface MonitorGridFilterInput {
  domainName?: InputMaybe<MonitorDomainNameInput>;
  friendlyName?: InputMaybe<MonitorNameInput>;
  fulltext?: InputMaybe<MonitorFullTextInput>;
  status?: InputMaybe<MonitorStatusInput>;
}

export interface MonitorGridInput {
  filter?: InputMaybe<MonitorGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface MonitorIds {
  __typename?: 'MonitorIds';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface MonitorLimitsDTO {
  __typename?: 'MonitorLimitsDTO';
  /**
   *
   *
   */
  downMonitors: Scalars['Int'];
  /**
   *
   *
   */
  monitors: Scalars['Int'];
  /**
   *
   *
   */
  monitorsLimit: Scalars['Int'];
  /**
   *
   *
   */
  pausedMonitors: Scalars['Int'];
  /**
   *
   *
   */
  upMonitors: Scalars['Int'];
}

export interface MonitorLimitsResponse {
  __typename?: 'MonitorLimitsResponse';
  /**
   *
   *
   */
  downMonitors: Scalars['Int'];
  /**
   *
   *
   */
  monitors: Scalars['Int'];
  /**
   *
   *
   */
  monitorsLimit: Scalars['Int'];
  /**
   *
   *
   */
  pausedMonitors: Scalars['Int'];
  /**
   *
   *
   */
  upMonitors: Scalars['Int'];
}

export interface MonitorLogDTO {
  __typename?: 'MonitorLogDTO';
  /**
   *
   *
   */
  duration: Scalars['Int'];
  /**
   *
   *
   */
  id: Scalars['Int'];
  /**
   *
   *
   */
  logDate: Scalars['DateTime'];
  /**
   *
   *
   */
  reason: Scalars['String'];
  /**
   *
   *
   */
  type: Scalars['String'];
}

export interface MonitorName {
  __typename?: 'MonitorName';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface MonitorNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface MonitorStatus {
  __typename?: 'MonitorStatus';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface MonitorStatusInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface MonitorsResponse {
  __typename?: 'MonitorsResponse';
  /**
   *
   *
   */
  rows: Array<Monitor>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface Mutation {
  __typename?: 'Mutation';
  /**
   *
   *
   */
  addTicketPriority: Priority;
  /**
   *
   *
   */
  addTicketStatus: Status;
  /**
   *
   *
   */
  addTicketType: Type;
  /**
   *
   *
   */
  addTodo: TicketTodo;
  /**
   *
   *
   */
  addTodos: Array<TicketTodo>;
  /**
   *
   *
   */
  blockUser: User;
  /**
   *
   *
   */
  changePassword: User;
  /**
   *
   *
   */
  changeTicketAssignee: Ticket;
  /**
   *
   *
   */
  createMonitor: Monitor;
  /**
   *
   *
   */
  createPhrase: Phrase;
  /**
   *
   *
   */
  createPhraseGroup: PhraseGroup;
  /**
   *
   *
   */
  createPhrases: Array<Phrase>;
  /**
   *
   *
   */
  createRole: Role;
  /**
   *
   *
   */
  createS4sInstance: S4sInstance;
  /**
   *
   *
   */
  createTicket: Ticket;
  /**
   *
   *
   */
  createTicketComment: TicketComment;
  /**
   *
   *
   */
  createUser: User;
  /**
   *
   *
   */
  deleteFile: File;
  /**
   *
   *
   */
  deleteMonitor: Scalars['Boolean'];
  /**
   *
   *
   */
  deletePhrase: Phrase;
  /**
   *
   *
   */
  deletePhraseGroup: PhraseGroup;
  /**
   *
   *
   */
  deletePhrases: Array<Phrase>;
  /**
   *
   *
   */
  deleteRole: Role;
  /**
   *
   *
   */
  deleteS4sInstance: S4sInstance;
  /**
   *
   *
   */
  deleteTicket: Ticket;
  /**
   *
   *
   */
  deleteTicketAttachment: TicketFile;
  /**
   *
   *
   */
  deleteTicketComment?: Maybe<TicketComment>;
  /**
   *
   *
   */
  deleteTicketCommentAttachment: TicketCommentFile;
  /**
   *
   *
   */
  deleteTicketPriority: Priority;
  /**
   *
   *
   */
  deleteTicketStatus: Status;
  /**
   *
   *
   */
  deleteTicketType: Type;
  /**
   *
   *
   */
  deleteTodo: TicketTodo;
  /**
   *
   *
   */
  deleteUserAvatar: User;
  /**
   *
   *
   */
  groupPhrases: PhraseGroup;
  /**
   *
   *
   */
  impersonate: User;
  /**
   *
   *
   */
  leaveImpersonation?: Maybe<User>;
  /**
   *
   *
   */
  loginUser?: Maybe<User>;
  /**
   *
   *
   */
  logoutUser: Scalars['Boolean'];
  /**
   *
   *
   */
  markAllNotificationRead: Scalars['Boolean'];
  /**
   *
   *
   */
  markReadNotification: Scalars['Boolean'];
  /**
   *
   *
   */
  markUnreadNotification: Scalars['Boolean'];
  /**
   *
   *
   */
  resetPassword: Scalars['String'];
  /**
   *
   *
   */
  sendResetPassword: Scalars['Boolean'];
  /**
   *
   *
   */
  setTicketPriority: Ticket;
  /**
   *
   *
   */
  setTranslations: Scalars['Boolean'];
  /**
   *
   *
   */
  syncRolePermissions: Role;
  /**
   *
   *
   */
  syncUserPermissions: SyncPermissionsResponse;
  /**
   *
   *
   */
  unblockUser: User;
  /**
   *
   *
   */
  ungroupPhrases?: Maybe<Array<Phrase>>;
  /**
   *
   *
   */
  unwatchTicket: Ticket;
  /**
   *
   *
   */
  updateDeal: Deal;
  /**
   *
   *
   */
  updateDomain: Domain;
  /**
   *
   *
   */
  updateMonitor: Monitor;
  /**
   *
   *
   */
  updateNotificationSetting: Scalars['Boolean'];
  /**
   *
   *
   */
  updatePhrase: Phrase;
  /**
   *
   *
   */
  updatePhraseGroup: PhraseGroup;
  /**
   *
   *
   */
  updateRole: Role;
  /**
   *
   *
   */
  updateS4sInstance: S4sInstance;
  /**
   *
   *
   */
  updateTicket: Ticket;
  /**
   *
   *
   */
  updateTicketComment: TicketComment;
  /**
   *
   *
   */
  updateTicketPriority: Priority;
  /**
   *
   *
   */
  updateTicketStatus: Status;
  /**
   *
   *
   */
  updateTicketType: Type;
  /**
   *
   *
   */
  updateTodo: TicketTodo;
  /**
   *
   *
   */
  updateUser: User;
  /**
   *
   *
   */
  updateUserDomainNotifications: User;
  /**
   *
   *
   */
  watchTicket: Ticket;
}

export interface MutationaddTicketPriorityArgs {
  color: ColorPaletteEnum;
  priority?: InputMaybe<Scalars['Float']>;
  value: Scalars['String'];
}

export interface MutationaddTicketStatusArgs {
  color: ColorPaletteEnum;
  priority?: InputMaybe<Scalars['Float']>;
  treatAsClosed?: InputMaybe<Scalars['Boolean']>;
  value: Scalars['String'];
  visibleInKanban?: InputMaybe<Scalars['Boolean']>;
}

export interface MutationaddTicketTypeArgs {
  color: ColorPaletteEnum;
  priority?: InputMaybe<Scalars['Float']>;
  value: Scalars['String'];
}

export interface MutationaddTodoArgs {
  priority?: InputMaybe<Scalars['Float']>;
  text: Scalars['String'];
  ticketId: Scalars['ID'];
}

export interface MutationaddTodosArgs {
  ticketId: Scalars['ID'];
  todos: Array<TodoInput>;
}

export interface MutationblockUserArgs {
  id: Scalars['ID'];
}

export interface MutationchangePasswordArgs {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}

export interface MutationchangeTicketAssigneeArgs {
  assignedUserId?: InputMaybe<Scalars['ID']>;
  ticketId: Scalars['ID'];
}

export interface MutationcreateMonitorArgs {
  domainId: Scalars['String'];
  url: Scalars['String'];
}

export interface MutationcreatePhraseArgs {
  checkPeriod: Scalars['Int'];
  domainId: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  userAssignedUrl?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
}

export interface MutationcreatePhraseGroupArgs {
  domainId: Scalars['String'];
  name: Scalars['String'];
  phraseIds?: InputMaybe<Array<Scalars['String']>>;
}

export interface MutationcreatePhrasesArgs {
  domainId: Scalars['String'];
  groupId?: InputMaybe<Scalars['String']>;
  phrases: Array<PhraseDTOInput>;
}

export interface MutationcreateRoleArgs {
  permissions: Array<InputMaybe<PermissionEnum>>;
  title: Scalars['String'];
}

export interface MutationcreateS4sInstanceArgs {
  db: Scalars['String'];
  host: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  prefix: Scalars['String'];
  user: Scalars['String'];
}

export interface MutationcreateTicketArgs {
  assignedToTicketId?: InputMaybe<Scalars['ID']>;
  assignedUserId?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  contentHtml?: InputMaybe<Scalars['String']>;
  deadline?: InputMaybe<Scalars['DateTime']>;
  domainId?: InputMaybe<Scalars['ID']>;
  priorityId?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<Scalars['DateTime']>;
  statusId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  todos?: InputMaybe<Array<TodoInput>>;
  typeId?: InputMaybe<Scalars['ID']>;
  watchers?: InputMaybe<Array<Scalars['ID']>>;
}

export interface MutationcreateTicketCommentArgs {
  content?: InputMaybe<Scalars['String']>;
  contentHtml?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  ticketId: Scalars['ID'];
}

export interface MutationcreateUserArgs {
  appLocale?: InputMaybe<LocaleEnum>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  contactUserId?: InputMaybe<Scalars['ID']>;
  crmCompanyId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  feSettings?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gmail?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  notifications?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<PermissionEnum>>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  supervisorId?: InputMaybe<Scalars['ID']>;
  teamIds?: InputMaybe<Array<Scalars['ID']>>;
  userType?: InputMaybe<UserTypeEnum>;
}

export interface MutationdeleteFileArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteMonitorArgs {
  id: Scalars['String'];
}

export interface MutationdeletePhraseArgs {
  id: Scalars['String'];
}

export interface MutationdeletePhraseGroupArgs {
  groupId: Scalars['String'];
}

export interface MutationdeletePhrasesArgs {
  ids: Array<Scalars['String']>;
}

export interface MutationdeleteRoleArgs {
  roleId: Scalars['String'];
}

export interface MutationdeleteS4sInstanceArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteTicketArgs {
  ticketId: Scalars['ID'];
}

export interface MutationdeleteTicketAttachmentArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteTicketCommentArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteTicketCommentAttachmentArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteTicketPriorityArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteTicketStatusArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteTicketTypeArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteTodoArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteUserAvatarArgs {
  id: Scalars['String'];
}

export interface MutationgroupPhrasesArgs {
  groupId: Scalars['String'];
  ids: Array<Scalars['String']>;
}

export interface MutationimpersonateArgs {
  accountId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
}

export interface MutationloginUserArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}

export interface MutationmarkReadNotificationArgs {
  id: Scalars['ID'];
}

export interface MutationmarkUnreadNotificationArgs {
  id: Scalars['ID'];
}

export interface MutationresetPasswordArgs {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}

export interface MutationsendResetPasswordArgs {
  email: Scalars['String'];
}

export interface MutationsetTicketPriorityArgs {
  id: Scalars['ID'];
  priorityValue: Scalars['Float'];
}

export interface MutationsetTranslationsArgs {
  translations: Array<GroupedTranslatablesInput>;
}

export interface MutationsyncRolePermissionsArgs {
  permissions: Array<PermissionEnum>;
  roleId: Scalars['String'];
}

export interface MutationsyncUserPermissionsArgs {
  directPermissions: Array<PermissionEnum>;
  role: Scalars['String'];
  userId: Scalars['String'];
}

export interface MutationunblockUserArgs {
  id: Scalars['ID'];
}

export interface MutationungroupPhrasesArgs {
  ids: Array<Scalars['String']>;
}

export interface MutationunwatchTicketArgs {
  ticketId: Scalars['ID'];
  userIds?: Array<InputMaybe<Scalars['ID']>>;
}

export interface MutationupdateDealArgs {
  activationAmount?: InputMaybe<Scalars['Float']>;
  active?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Float']>;
  analyticsMonthEndDate?: InputMaybe<Scalars['DateTime']>;
  contentQuarterly?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<CurrencyEnum>;
  domainId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  extraData?: InputMaybe<DealExtraDataDTOInput>;
  id: Scalars['ID'];
  indefinite?: InputMaybe<Scalars['Boolean']>;
  isLatest?: InputMaybe<Scalars['Boolean']>;
  maxBudget?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  origRealStartDate?: InputMaybe<Scalars['DateTime']>;
  origStartDate?: InputMaybe<Scalars['DateTime']>;
  previousDealId?: InputMaybe<Scalars['ID']>;
  product?: InputMaybe<DealCrmProductEnum>;
  realStartDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  textReductionPercent?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
}

export interface MutationupdateDomainArgs {
  assignedPartnerUserIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  positionedDomain?: InputMaybe<Scalars['String']>;
  s4sInstanceId?: InputMaybe<Scalars['String']>;
}

export interface MutationupdateMonitorArgs {
  id: Scalars['String'];
  newUrl: Scalars['String'];
}

export interface MutationupdateNotificationSettingArgs {
  channel: Scalars['String'];
  id: Scalars['ID'];
  value: Scalars['Boolean'];
}

export interface MutationupdatePhraseArgs {
  checkPeriod?: InputMaybe<Scalars['Int']>;
  domainId?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  userAssignedUrl?: InputMaybe<Scalars['String']>;
}

export interface MutationupdatePhraseGroupArgs {
  groupId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phraseIds?: InputMaybe<Array<Scalars['String']>>;
}

export interface MutationupdateRoleArgs {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
}

export interface MutationupdateS4sInstanceArgs {
  db?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  port?: InputMaybe<Scalars['Int']>;
  prefix?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
}

export interface MutationupdateTicketArgs {
  assignedToTicketId?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  contentHtml?: InputMaybe<Scalars['String']>;
  deadline?: InputMaybe<Scalars['DateTime']>;
  domainId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  ownerId?: InputMaybe<Scalars['ID']>;
  priorityId?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<Scalars['DateTime']>;
  statusId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
}

export interface MutationupdateTicketCommentArgs {
  content?: InputMaybe<Scalars['String']>;
  contentHtml?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}

export interface MutationupdateTicketPriorityArgs {
  archived?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<ColorPaletteEnum>;
  id: Scalars['ID'];
  priority?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['String']>;
}

export interface MutationupdateTicketStatusArgs {
  archived?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<ColorPaletteEnum>;
  id: Scalars['ID'];
  priority?: InputMaybe<Scalars['Float']>;
  treatAsClosed?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
  visibleInKanban?: InputMaybe<Scalars['Boolean']>;
}

export interface MutationupdateTicketTypeArgs {
  archived?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<ColorPaletteEnum>;
  id: Scalars['ID'];
  priority?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['String']>;
}

export interface MutationupdateTodoArgs {
  id: Scalars['ID'];
  priority?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
}

export interface MutationupdateUserArgs {
  id: Scalars['ID'];
  userInput: UpdateUserInput;
}

export interface MutationupdateUserDomainNotificationsArgs {
  domainId: Scalars['String'];
  isSubscribed: Scalars['Boolean'];
  userId: Scalars['ID'];
}

export interface MutationwatchTicketArgs {
  ticketId: Scalars['ID'];
  userIds?: Array<InputMaybe<Scalars['ID']>>;
}

export interface Name {
  __typename?: 'Name';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface NameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface NoBlockedFilter {
  __typename?: 'NoBlockedFilter';
  /**
   *
   *
   */
  value: Scalars['Boolean'];
}

export interface NoBlockedFilterInput {
  value: Scalars['Boolean'];
}

export interface Notification {
  __typename?: 'Notification';
  created_at: Scalars['String'];
  /**
   *
   *
   */
  data: Scalars['String'];
  id: Scalars['ID'];
  owner: User;
  read_at?: Maybe<Scalars['String']>;
  type: Scalars['String'];
}

export interface NotificationGridFilterInput {
  date?: InputMaybe<DateInput>;
  read?: InputMaybe<ReadInput>;
  type?: InputMaybe<NotificationTypeInput>;
}

export interface NotificationGridInput {
  filter?: InputMaybe<NotificationGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface NotificationType {
  __typename?: 'NotificationType';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface NotificationTypeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface NotificationsResponse {
  __typename?: 'NotificationsResponse';
  /**
   *
   *
   */
  rows: Array<Notification>;
  /**
   *
   *
   */
  total: Scalars['Int'];
  /**
   *
   *
   */
  total_unread: Scalars['Int'];
}

export interface OnlyCreated {
  __typename?: 'OnlyCreated';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export enum OperatorEnum {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  IN = 'IN',
  LIKE = 'LIKE',
  LT = 'LT',
  LTE = 'LTE',
  NEQ = 'NEQ',
  RANGE = 'RANGE',
}

export interface OwnerUserId {
  __typename?: 'OwnerUserId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface OwnerUserIdInput {
  value: Array<Scalars['ID']>;
}

export interface Permission {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
}

export enum PermissionEnum {
  ASSIGN_ROLES = 'ASSIGN_ROLES',
  BLOCK_USERS = 'BLOCK_USERS',
  CREATE_DEALS = 'CREATE_DEALS',
  CREATE_MONITORS = 'CREATE_MONITORS',
  CREATE_PHRASES = 'CREATE_PHRASES',
  CREATE_S4S_INSTANCES = 'CREATE_S4S_INSTANCES',
  CREATE_TEAMS = 'CREATE_TEAMS',
  CREATE_TICKETS = 'CREATE_TICKETS',
  CREATE_USERS = 'CREATE_USERS',
  DELETE_MONITORS = 'DELETE_MONITORS',
  DELETE_PHRASES = 'DELETE_PHRASES',
  DELETE_S4S_INSTANCES = 'DELETE_S4S_INSTANCES',
  DELETE_TEAMS = 'DELETE_TEAMS',
  DELETE_TICKETS = 'DELETE_TICKETS',
  DELETE_TICKET_COMMENTS = 'DELETE_TICKET_COMMENTS',
  EDIT_DEALS = 'EDIT_DEALS',
  EDIT_DOMAINS = 'EDIT_DOMAINS',
  EDIT_TICKET_COMMENTS = 'EDIT_TICKET_COMMENTS',
  GROUP_PHRASES = 'GROUP_PHRASES',
  IMPERSONATE_USERS = 'IMPERSONATE_USERS',
  LIST_BLOCKED_USERS = 'LIST_BLOCKED_USERS',
  LIST_CRM_COMPANIES = 'LIST_CRM_COMPANIES',
  LIST_DEALS = 'LIST_DEALS',
  LIST_DOMAINS = 'LIST_DOMAINS',
  LIST_MONITORS = 'LIST_MONITORS',
  LIST_PHRASES = 'LIST_PHRASES',
  LIST_PROTECTED_FIELDS = 'LIST_PROTECTED_FIELDS',
  LIST_S4S_INSTANCES = 'LIST_S4S_INSTANCES',
  LIST_TEAMS = 'LIST_TEAMS',
  LIST_TICKETS = 'LIST_TICKETS',
  LIST_USERS = 'LIST_USERS',
  LIST_USER_ACTIVITIES = 'LIST_USER_ACTIVITIES',
  MANAGE_ADMINS = 'MANAGE_ADMINS',
  MANAGE_DOMAINS = 'MANAGE_DOMAINS',
  MANAGE_DOMAIN_USERS = 'MANAGE_DOMAIN_USERS',
  MANAGE_META_TICKETS = 'MANAGE_META_TICKETS',
  MANAGE_ROLES = 'MANAGE_ROLES',
  MANAGE_TRANSLATIONS = 'MANAGE_TRANSLATIONS',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SHOW_TEAM_STATS = 'SHOW_TEAM_STATS',
  UPDATE_MONITORS = 'UPDATE_MONITORS',
  UPDATE_PHRASES = 'UPDATE_PHRASES',
  UPDATE_S4S_INSTANCES = 'UPDATE_S4S_INSTANCES',
  UPDATE_TEAMS = 'UPDATE_TEAMS',
  UPDATE_TICKETS = 'UPDATE_TICKETS',
  UPDATE_USERS = 'UPDATE_USERS',
}

export interface PermissionsField {
  __typename?: 'PermissionsField';
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface PermissionsFieldInput {
  value: Array<PermissionEnum>;
}

export interface PermissionsResponse {
  __typename?: 'PermissionsResponse';
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  key?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  value: Scalars['String'];
}

export interface Phrase {
  __typename?: 'Phrase';
  check_period: Scalars['Int'];
  created_at: Scalars['String'];
  domain?: Maybe<Domain>;
  group?: Maybe<PhraseGroup>;
  id: Scalars['ID'];
  /**
   *
   *
   */
  import_id?: Maybe<Scalars['Int']>;
  last_check?: Maybe<Scalars['String']>;
  location: LocationEnum;
  /**
   *
   *
   */
  location_key?: Maybe<Scalars['String']>;
  positions: Array<Maybe<PhrasePosition>>;
  positionsOrdered: Array<Maybe<PhrasePositionOrdered>>;
  search?: Maybe<PhraseSearch>;
  start_position?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  user_assigned_url?: Maybe<Scalars['String']>;
  value: Scalars['String'];
}

export interface PhraseDTO {
  __typename?: 'PhraseDTO';
  /**
   *
   *
   */
  check_period?: Maybe<Scalars['Int']>;
  /**
   *
   *
   */
  domain_id?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  group_id?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  location?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  user_assigned_url?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  user_id?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  value: Scalars['String'];
}

export interface PhraseDTOInput {
  checkPeriod?: InputMaybe<Scalars['Int']>;
  domainId?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  userAssignedUrl?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
}

export interface PhraseDomainId {
  __typename?: 'PhraseDomainId';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface PhraseDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface PhraseDomainName {
  __typename?: 'PhraseDomainName';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface PhraseDomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface PhraseGridFilterInput {
  dateRange?: InputMaybe<DateRangeInput>;
  domainId?: InputMaybe<PhraseDomainIdInput>;
  domainName?: InputMaybe<PhraseDomainNameInput>;
}

export interface PhraseGridInput {
  filter?: InputMaybe<PhraseGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface PhraseGroup {
  __typename?: 'PhraseGroup';
  domain_id: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface PhrasePosition {
  __typename?: 'PhrasePosition';
  created_at?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  id: Scalars['ID'];
  phrase_id: Scalars['ID'];
  position: Scalars['Int'];
  /**
   *
   *
   */
  positionDifference?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
}

export interface PhrasePositionOrdered {
  __typename?: 'PhrasePositionOrdered';
  /**
   *
   *
   */
  date: Scalars['String'];
  /**
   *
   *
   */
  position?: Maybe<Scalars['Int']>;
  /**
   *
   *
   */
  positionDifference?: Maybe<Scalars['Int']>;
  /**
   *
   *
   */
  url?: Maybe<Scalars['String']>;
}

export interface PhraseSearch {
  __typename?: 'PhraseSearch';
  count: Scalars['Int'];
  id: Scalars['ID'];
  last_check?: Maybe<Scalars['String']>;
  phrase: Phrase;
  value: Scalars['String'];
}

export interface PhrasesResponse {
  __typename?: 'PhrasesResponse';
  /**
   *
   *
   */
  rows: Array<Phrase>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface Priority {
  __typename?: 'Priority';
  archived: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Float']>;
  value: Scalars['String'];
}

export interface PriorityId {
  __typename?: 'PriorityId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface PriorityIdInput {
  value: Array<Scalars['ID']>;
}

export interface Query {
  __typename?: 'Query';
  /**
   *
   *
   */
  activeUser?: Maybe<User>;
  /**
   *
   *
   */
  colorPalette: Array<EnumResponse>;
  /**
   *
   *
   */
  countries: Array<EnumTranslationResponse>;
  /**
   *
   *
   */
  crmCompanies: Array<CrmCompanyDTO>;
  /**
   *
   *
   */
  currencies: Array<EnumResponse>;
  /**
   *
   *
   */
  deal: Deal;
  /**
   *
   *
   */
  dealDiscountUnits: Array<EnumResponse>;
  /**
   *
   *
   */
  dealProducts: Array<EnumTranslationResponse>;
  /**
   *
   *
   */
  dealTypes: Array<EnumTranslationResponse>;
  /**
   *
   *
   */
  deals: DealsResponse;
  /**
   *
   *
   */
  deletedTickets: Array<Ticket>;
  /**
   *
   *
   */
  directUserPermissions: Array<Scalars['String']>;
  /**
   *
   *
   */
  domain: Domain;
  /**
   *
   *
   */
  domains: DomainsResponse;
  /**
   *
   *
   */
  domainsWithAccountId: Array<Domain>;
  /**
   *
   *
   */
  eventTypes: Array<Scalars['String']>;
  /**
   *
   *
   */
  externalReports: Array<ExternalReportDTO>;
  /**
   *
   *
   */
  files: Array<File>;
  /**
   *
   *
   */
  groupedPermissions: Array<GroupedPermissionsResponse>;
  /**
   *
   *
   */
  groupedPhrases: Array<GroupedPhrases>;
  /**
   *
   *
   */
  locales: Array<EnumTranslationResponse>;
  /**
   *
   *
   */
  locations: Array<EnumTranslationResponse>;
  /**
   *
   *
   */
  monitor: Monitor;
  /**
   *
   *
   */
  monitorLimits: MonitorLimitsResponse;
  /**
   *
   *
   */
  monitorLogs: Array<MonitorLogDTO>;
  /**
   *
   *
   */
  monitors: MonitorsResponse;
  /**
   *
   *
   */
  notificationGroups: Array<EnumTranslationResponse>;
  /**
   *
   *
   */
  notificationSettings: Array<SettingGroup>;
  /**
   *
   *
   */
  permissions: Array<PermissionsResponse>;
  /**
   *
   *
   */
  phraseGroups: Array<PhraseGroup>;
  /**
   *
   *
   */
  phrases: PhrasesResponse;
  /**
   *
   *
   */
  recentTicketActivityStream: Array<Activity>;
  /**
   *
   *
   */
  regions: Array<Scalars['String']>;
  /**
   *
   *
   */
  roles: Array<RoleResponse>;
  /**
   *
   *
   */
  s4sInstance: S4sInstance;
  /**
   *
   *
   */
  s4sInstances: S4sResponse;
  /**
   *
   *
   */
  ticket: Ticket;
  /**
   *
   *
   */
  ticketActivities: TicketActivitiesResponse;
  /**
   *
   *
   */
  ticketActivityStream: Array<TicketActivityResponse>;
  /**
   *
   *
   */
  ticketComments: CommentsResponse;
  /**
   *
   *
   */
  ticketPriorities: Array<Priority>;
  /**
   *
   *
   */
  ticketStatuses: Array<Status>;
  /**
   *
   *
   */
  ticketTodoTypes: Array<EnumTranslationResponse>;
  /**
   *
   *
   */
  ticketTodos: Array<TicketTodo>;
  /**
   *
   *
   */
  ticketTypes: Array<Type>;
  /**
   *
   *
   */
  ticketWatchers: Array<User>;
  /**
   *
   *
   */
  tickets: TicketsResponse;
  /**
   *
   *
   */
  translatables: Array<TranslatablesResponse>;
  /**
   *
   *
   */
  user: User;
  /**
   *
   *
   */
  userActivityStream: Array<ActivityResponse>;
  /**
   *
   *
   */
  userNotifications: NotificationsResponse;
  /**
   *
   *
   */
  userStatistics: UserStatisticsResponse;
  /**
   *
   *
   */
  users: UsersResponse;
}

export interface QuerycrmCompaniesArgs {
  search?: InputMaybe<Scalars['String']>;
}

export interface QuerydealArgs {
  id: Scalars['ID'];
}

export interface QuerydealsArgs {
  grid?: InputMaybe<DealGridInput>;
}

export interface QuerydirectUserPermissionsArgs {
  id: Scalars['String'];
}

export interface QuerydomainArgs {
  id: Scalars['ID'];
}

export interface QuerydomainsArgs {
  grid?: InputMaybe<DomainGridInput>;
}

export interface QueryexternalReportsArgs {
  domainId?: InputMaybe<Scalars['ID']>;
}

export interface QuerygroupedPhrasesArgs {
  grid?: InputMaybe<GroupedPhraseGridInput>;
}

export interface QuerymonitorArgs {
  id: Scalars['String'];
}

export interface QuerymonitorLogsArgs {
  days?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
}

export interface QuerymonitorsArgs {
  grid?: InputMaybe<MonitorGridInput>;
}

export interface QueryphraseGroupsArgs {
  domainId: Scalars['String'];
}

export interface QueryphrasesArgs {
  grid?: InputMaybe<PhraseGridInput>;
}

export interface QueryrecentTicketActivityStreamArgs {
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryregionsArgs {
  country: Scalars['String'];
}

export interface Querys4sInstanceArgs {
  id: Scalars['ID'];
}

export interface Querys4sInstancesArgs {
  grid?: InputMaybe<S4sGridInput>;
}

export interface QueryticketArgs {
  id: Scalars['ID'];
}

export interface QueryticketActivitiesArgs {
  grid?: InputMaybe<TicketActivitiesGridInput>;
}

export interface QueryticketActivityStreamArgs {
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface QueryticketCommentsArgs {
  grid?: InputMaybe<TicketCommentsGridInput>;
}

export interface QueryticketPrioritiesArgs {
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryticketStatusesArgs {
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryticketTodosArgs {
  ticketId: Scalars['ID'];
}

export interface QueryticketTypesArgs {
  includeArchived?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryticketWatchersArgs {
  id: Scalars['ID'];
}

export interface QueryticketsArgs {
  grid?: InputMaybe<TicketGridInput>;
}

export interface QueryuserArgs {
  id: Scalars['ID'];
}

export interface QueryuserActivityStreamArgs {
  date?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface QueryuserNotificationsArgs {
  grid?: InputMaybe<NotificationGridInput>;
}

export interface QueryuserStatisticsArgs {
  id: Scalars['ID'];
}

export interface QueryusersArgs {
  grid?: InputMaybe<UserGridInput>;
}

export interface Read {
  __typename?: 'Read';
  /**
   *
   *
   */
  value?: Maybe<Scalars['Boolean']>;
}

export interface ReadInput {
  value?: InputMaybe<Scalars['Boolean']>;
}

export interface Role {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  title?: Maybe<Scalars['String']>;
}

export interface RoleField {
  __typename?: 'RoleField';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface RoleFieldInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface RoleResponse {
  __typename?: 'RoleResponse';
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  name: Scalars['String'];
  /**
   *
   *
   */
  permissions: Array<Scalars['String']>;
  /**
   *
   *
   */
  title: Scalars['String'];
}

export interface S4sGridFilterInput {
  name?: InputMaybe<S4sInstanceNameInput>;
}

export interface S4sGridInput {
  filter?: InputMaybe<S4sGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface S4sInstance {
  __typename?: 'S4sInstance';
  db: Scalars['String'];
  domains: Array<Maybe<Domain>>;
  host: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phrases?: Maybe<Scalars['Int']>;
  port: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
}

export interface S4sInstanceName {
  __typename?: 'S4sInstanceName';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface S4sInstanceNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface S4sResponse {
  __typename?: 'S4sResponse';
  /**
   *
   *
   */
  rows: Array<S4sInstance>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface Setting {
  __typename?: 'Setting';
  /**
   *
   *
   */
  channel: Scalars['String'];
  /**
   *
   *
   */
  group?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  key: Scalars['String'];
  /**
   *
   *
   */
  name: Scalars['String'];
  /**
   *
   *
   */
  value: Scalars['Boolean'];
}

export interface SettingGroup {
  __typename?: 'SettingGroup';
  /**
   *
   *
   */
  group: Scalars['String'];
  /**
   *
   *
   */
  groupName: Scalars['String'];
  /**
   *
   *
   */
  settings: Array<Setting>;
}

export enum SortDirEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortTypeInput {
  dir: SortDirEnum;
  field: Scalars['String'];
}

export interface Start {
  __typename?: 'Start';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface StartInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']>;
}

export interface Status {
  __typename?: 'Status';
  archived: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Float']>;
  treat_as_closed: Scalars['Boolean'];
  value: Scalars['String'];
  visible_in_kanban: Scalars['Boolean'];
}

export interface StatusId {
  __typename?: 'StatusId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface StatusIdInput {
  value: Array<Scalars['ID']>;
}

export interface SyncPermissionsResponse {
  __typename?: 'SyncPermissionsResponse';
  /**
   *
   *
   */
  permissions?: Maybe<Array<Scalars['String']>>;
  /**
   *
   *
   */
  role?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  user: User;
}

export interface Team {
  __typename?: 'Team';
  id: Scalars['ID'];
  leader: User;
  members: Array<User>;
  name: Scalars['String'];
  /**
   *
   *
   */
  totalMembers: Scalars['Int'];
}

export interface TeamFulltext {
  __typename?: 'TeamFulltext';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface TeamStatisticsResponse {
  __typename?: 'TeamStatisticsResponse';
  /**
   *
   *
   */
  details: Array<Details>;
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  totals: Totals;
}

export interface TeamsResponse {
  __typename?: 'TeamsResponse';
  /**
   *
   *
   */
  rows: Array<Team>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface Ticket {
  __typename?: 'Ticket';
  assigned?: Maybe<User>;
  assignedTickets: Array<Maybe<Ticket>>;
  assignedToTicket?: Maybe<Ticket>;
  comment: Array<Maybe<TicketComment>>;
  content?: Maybe<Scalars['String']>;
  content_html?: Maybe<Scalars['String']>;
  created_at: Scalars['String'];
  deadline?: Maybe<Scalars['String']>;
  domain?: Maybe<Domain>;
  file: Array<Maybe<TicketFile>>;
  id: Scalars['ID'];
  importStatus?: Maybe<ImportStatus>;
  importType?: Maybe<ImportType>;
  /**
   *
   *
   */
  is_imported: Scalars['Boolean'];
  owner?: Maybe<User>;
  priority?: Maybe<Priority>;
  priority_value?: Maybe<Scalars['Float']>;
  /**
   *
   *
   */
  recentActivity: Array<Activity>;
  sequence?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  title: Scalars['String'];
  todo: Array<Maybe<TicketTodo>>;
  type?: Maybe<Type>;
  updatedBy?: Maybe<User>;
  updated_at?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  watchers: Array<User>;
}

export interface TicketAclIds {
  __typename?: 'TicketAclIds';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface TicketActivitiesGridFilterInput {
  createdAt?: InputMaybe<ActivitiesCreatedAtInput>;
  ticketId?: InputMaybe<TicketIdInput>;
  userId?: InputMaybe<ActivitiesUserIdInput>;
}

export interface TicketActivitiesGridInput {
  filter?: InputMaybe<TicketActivitiesGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TicketActivitiesResponse {
  __typename?: 'TicketActivitiesResponse';
  /**
   *
   *
   */
  rows: Array<Activity>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface TicketActivityResponse {
  __typename?: 'TicketActivityResponse';
  /**
   *
   *
   */
  date: Scalars['String'];
  /**
   *
   *
   */
  items: Array<Activity>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface TicketComment {
  __typename?: 'TicketComment';
  content?: Maybe<Scalars['String']>;
  content_html?: Maybe<Scalars['String']>;
  created_at: Scalars['String'];
  deleted_at?: Maybe<Scalars['String']>;
  file: Array<Maybe<TicketCommentFile>>;
  id: Scalars['ID'];
  /**
   *
   *
   */
  is_imported: Scalars['Boolean'];
  ticket: Ticket;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
}

export interface TicketCommentFile {
  __typename?: 'TicketCommentFile';
  id: Scalars['ID'];
  import_id?: Maybe<Scalars['String']>;
  /**
   *
   *
   * @deprecated Use "id" and /api/ticket-comment/file/{id} route
   */
  path: Scalars['String'];
  sizeInKb: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  user: User;
}

export interface TicketCommentsGridFilterInput {
  createdAt?: InputMaybe<CommentsCreatedAtInput>;
  ticketId?: InputMaybe<CommentsTicketIdInput>;
  userId?: InputMaybe<CommentsUserIdInput>;
}

export interface TicketCommentsGridInput {
  filter?: InputMaybe<TicketCommentsGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TicketDomainId {
  __typename?: 'TicketDomainId';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface TicketDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface TicketDomainName {
  __typename?: 'TicketDomainName';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface TicketDomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface TicketFile {
  __typename?: 'TicketFile';
  id: Scalars['ID'];
  import_id?: Maybe<Scalars['String']>;
  /**
   *
   *
   * @deprecated Use "id" and /api/ticket/file/{id} route
   */
  path: Scalars['String'];
  sizeInKb: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  user: User;
}

export interface TicketFulltext {
  __typename?: 'TicketFulltext';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface TicketFulltextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface TicketGridFilterInput {
  assignedUserId?: InputMaybe<AssignedUserIdInput>;
  closed?: InputMaybe<ClosedInput>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<CreatedAtInput>;
  deadline?: InputMaybe<DeadlineInput>;
  domainId?: InputMaybe<TicketDomainIdInput>;
  domainName?: InputMaybe<TicketDomainNameInput>;
  fulltext?: InputMaybe<TicketFulltextInput>;
  importStatusId?: InputMaybe<ImportStatusIdInput>;
  importTypeId?: InputMaybe<ImportTypeIdInput>;
  isImported?: InputMaybe<ImportedFilterInput>;
  ownerUserId?: InputMaybe<OwnerUserIdInput>;
  priorityId?: InputMaybe<PriorityIdInput>;
  start?: InputMaybe<StartInput>;
  statusId?: InputMaybe<StatusIdInput>;
  title?: InputMaybe<TitleInput>;
  typeId?: InputMaybe<TypeIdInput>;
  updatedAt?: InputMaybe<UpdatedAtInput>;
  watchedBy?: InputMaybe<WatchedByInput>;
}

export interface TicketGridInput {
  filter?: InputMaybe<TicketGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TicketId {
  __typename?: 'TicketId';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface TicketIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface TicketTodo {
  __typename?: 'TicketTodo';
  created_at: Scalars['String'];
  deleted_at: Scalars['String'];
  id: Scalars['ID'];
  /**
   *
   *
   */
  is_imported: Scalars['Boolean'];
  priority?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  text: Scalars['String'];
  ticket: Ticket;
  user?: Maybe<User>;
}

export interface TicketsResponse {
  __typename?: 'TicketsResponse';
  /**
   *
   *
   */
  rows: Array<Ticket>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface Title {
  __typename?: 'Title';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface TitleInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface Todo {
  __typename?: 'Todo';
  /**
   *
   *
   */
  priority: Scalars['Float'];
  /**
   *
   *
   */
  text: Scalars['String'];
}

export interface TodoInput {
  priority?: InputMaybe<Scalars['Float']>;
  text: Scalars['String'];
}

export interface Totals {
  __typename?: 'Totals';
  /**
   *
   *
   */
  budget: Scalars['Int'];
  /**
   *
   *
   */
  closed: Scalars['Int'];
  /**
   *
   *
   */
  domains: Scalars['Int'];
  /**
   *
   *
   */
  open: Scalars['Int'];
  /**
   *
   *
   */
  overdue: Scalars['Int'];
  /**
   *
   *
   */
  thisWeek: Scalars['Int'];
  /**
   *
   *
   */
  today: Scalars['Int'];
}

export interface TranslatableObject {
  __typename?: 'TranslatableObject';
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  translations: Array<Maybe<TranslationsObject>>;
  /**
   *
   *
   */
  type?: Maybe<Scalars['String']>;
}

export interface TranslatableObjectInput {
  id: Scalars['String'];
  translations: Array<TranslationsObjectInput>;
  type?: InputMaybe<Scalars['String']>;
}

export interface TranslatablesResponse {
  __typename?: 'TranslatablesResponse';
  /**
   *
   *
   */
  id: Scalars['String'];
  /**
   *
   *
   */
  rows: Array<Maybe<TranslatableObject>>;
  /**
   *
   *
   */
  value: Scalars['String'];
}

export interface TranslationsObject {
  __typename?: 'TranslationsObject';
  /**
   *
   *
   */
  language_code: Scalars['String'];
  /**
   *
   *
   */
  value: Scalars['String'];
}

export interface TranslationsObjectInput {
  language_code: Scalars['String'];
  value: Scalars['String'];
}

export interface Type {
  __typename?: 'Type';
  archived: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Float']>;
  value: Scalars['String'];
}

export interface TypeId {
  __typename?: 'TypeId';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface TypeIdInput {
  value: Array<Scalars['ID']>;
}

export interface TypesResponse {
  __typename?: 'TypesResponse';
  /**
   *
   *
   */
  type: Array<Type>;
}

export interface UpdateUserInput {
  appLocale?: InputMaybe<LocaleEnum>;
  contactUserId?: InputMaybe<Scalars['ID']>;
  crmCompanyId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  feSettings?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gmail?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  navbarUrl?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<PermissionEnum>>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  supervisorId?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
  tokenExpire?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<UserTypeEnum>;
}

export interface UpdatedAt {
  __typename?: 'UpdatedAt';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface UpdatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']>;
}

export interface User {
  __typename?: 'User';
  /**
   *
   *
   */
  allowedPermissions: Array<Scalars['String']>;
  app_locale: LocaleEnum;
  /**
   *
   *
   */
  assignedUsers: Array<Maybe<User>>;
  avatar?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  contactUser?: Maybe<User>;
  crm_company_id?: Maybe<Scalars['Int']>;
  /**
   *
   *
   */
  current_account_id?: Maybe<Scalars['String']>;
  domains_notifications?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  external_id?: Maybe<Scalars['String']>;
  fe_settings?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  gmail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /**
   *
   *
   */
  is_impersonated: Scalars['Boolean'];
  last_name: Scalars['String'];
  last_seen?: Maybe<Scalars['String']>;
  navbar_url?: Maybe<Scalars['String']>;
  notifications: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  relogin?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  roleName?: Maybe<Scalars['String']>;
  /**
   *
   *
   */
  supervisor?: Maybe<User>;
  /**
   *
   *
   */
  teams?: Maybe<Array<Team>>;
  token_expire?: Maybe<Scalars['String']>;
  user_type?: Maybe<Scalars['String']>;
  workerUsers: Array<Maybe<User>>;
}

export interface UserCreatedAt {
  __typename?: 'UserCreatedAt';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['DateTime']>;
}

export interface UserFulltext {
  __typename?: 'UserFulltext';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface UserFulltextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface UserGridFilterInput {
  active?: InputMaybe<ActiveFieldInput>;
  email?: InputMaybe<EmailFieldInput>;
  firstName?: InputMaybe<Scalars['String']>;
  fulltext?: InputMaybe<UserFulltextInput>;
  gmail?: InputMaybe<GmailFieldInput>;
  lastName?: InputMaybe<Scalars['String']>;
  noBlocked?: InputMaybe<NoBlockedFilterInput>;
  permissions?: InputMaybe<PermissionsFieldInput>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleFieldInput>;
}

export interface UserGridInput {
  filter?: InputMaybe<UserGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface UserId {
  __typename?: 'UserId';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface UserIds {
  __typename?: 'UserIds';
  /**
   *
   *
   */
  operator: OperatorEnum;
  /**
   *
   *
   */
  value: Array<Scalars['String']>;
}

export interface UserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']>;
}

export interface UserStatisticsResponse {
  __typename?: 'UserStatisticsResponse';
  /**
   *
   *
   */
  closed: Scalars['Int'];
  /**
   *
   *
   */
  open: Scalars['Int'];
  /**
   *
   *
   */
  overdue: Scalars['Int'];
  /**
   *
   *
   */
  thisWeek: Scalars['Int'];
  /**
   *
   *
   */
  today: Scalars['Int'];
}

export enum UserTypeEnum {
  KIWI = 'KIWI',
  LOCAL = 'LOCAL',
  ZEUS = 'ZEUS',
}

export interface UsersResponse {
  __typename?: 'UsersResponse';
  /**
   *
   *
   */
  rows: Array<User>;
  /**
   *
   *
   */
  total: Scalars['Int'];
}

export interface WatchedBy {
  __typename?: 'WatchedBy';
  /**
   *
   *
   */
  value: Array<Scalars['ID']>;
}

export interface WatchedByInput {
  value: Array<Scalars['ID']>;
}
