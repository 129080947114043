import {
  ContentBlock,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
  EntityInstance,
} from 'draft-js';
import { noop } from 'lodash';
import { stateToHTML } from 'draft-js-export-html';
import { Options as ImportFromHtmlOptions, stateFromHTML } from 'draft-js-import-html';

import { logger } from './logger';
import { parseJson } from './jsonHelpers';

const stateFromHTMLOptions: ImportFromHtmlOptions = {
  customBlockFn: (element: Element) => {
    if (element.className === 'rich-text-editor__quote-block') {
      return { type: 'quote-block' };
    }
  },
};

const stateToHTMLOptions = {
  entityStyleFn: (entity: EntityInstance) => {
    const entityType = entity.getType().toLowerCase();

    if (entityType === 'link') {
      const data = entity.getData();

      return {
        element: 'a',
        attributes: {
          href: data.url,
          rel: 'noreferrer noopener',
          target: '_blank',
        },
      };
    }
  },

  blockStyleFn: (block: ContentBlock) => {
    if (block.getType() === 'quote-block') {
      return {
        element: 'div',
        attributes: {
          class: 'rich-text-editor__quote-block',
        },
      };
    }

    if (block.getData().get('style')) {
      return {
        style: block.getData().get('style'),
      };
    }
  },
};

export const htmlToEditorState = (contentHtml: string) => {
  try {
    const contentState = stateFromHTML(contentHtml, stateFromHTMLOptions);

    return EditorState.createWithContent(contentState);
  } catch (e: unknown) {
    logger(e);
    return null;
  }
};

export const editorStateToHtml = (editorState: EditorState) => {
  try {
    const contentState = editorState.getCurrentContent();

    return stateToHTML(contentState, stateToHTMLOptions);
  } catch (e: unknown) {
    logger(e);
    return null;
  }
};

export const convertFromHtmlToContentState = (contentHtml: string) => {
  try {
    return stateFromHTML(contentHtml, stateFromHTMLOptions);
  } catch (e: unknown) {
    logger(e);

    const blocksFromHTML = convertFromHTML(contentHtml);

    return ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
  }
};

export const htmlToRawContentString = (
  contentHtml: string,
  onError: (e: unknown) => void = noop,
) => {
  try {
    const contentState = convertFromHtmlToContentState(contentHtml);

    return JSON.stringify(convertToRaw(contentState));
  } catch (e: unknown) {
    logger(e);
    onError(e);

    return null;
  }
};

export const contentStateToHtml = (contentState: ContentState) => {
  try {
    stateToHTML(contentState, stateToHTMLOptions);
  } catch (e: unknown) {
    logger(e);

    return null;
  }
};

export const rawContentStringToHtml = (content: string, onError: (e: unknown) => void) => {
  try {
    const contentState = convertFromRaw(parseJson(content));

    return stateToHTML(contentState, stateToHTMLOptions);
  } catch (e: unknown) {
    logger(e);
    onError(e);

    return null;
  }
};

interface InitialProps {
  contentHtml?: string | null;
  onError?: (err: unknown) => void;
}

export const initialTextEditorValue = (props?: InitialProps) => {
  if (!props) return JSON.stringify(EditorState.createEmpty());

  const { contentHtml, onError = noop } = props;

  if (contentHtml) return htmlToRawContentString(contentHtml, onError) ?? '';

  return JSON.stringify(EditorState.createEmpty());
};

export const isTextEditorEmpty = (content: string) => {
  const currentContent = convertFromRaw(parseJson(content));

  const editorState = EditorState.createWithContent(currentContent);

  return !editorState.getCurrentContent().hasText();
};
